<template>
  <template-base>
    <form>
      <b-row>
        <b-col cols=12>
          <b-form-group label="Nome Fantasia*" label-for="nomeFantasia">
            <b-form-input
              id="nomeFantasia"
              v-model="model.nomeFantasia"
              autocomplete="off"
              class="invision-input"
              name="nomeFantasia"
              :disabled="isBusy"
              v-validate="{required: true}"
              :state="validateState('nomeFantasia')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Alteração da Logo" label-for="logoFile">
            <b-form-file
              id="logoFile"
              name="logoFile"
              v-model="model.logoFile"
              placeholder="Escolha ou arraste um arquivo .png"
              drop-placeholder="Arraste o aquivo aqui..."
              accept="image/png"
              :disabled="isBusy"
              browse-text="Buscar"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Alteração do favicon (ícone da aba do site)" label-for="faviconFile">
            <b-form-file
              id="faviconFile"
              name="faviconFile"
              v-model="model.faviconFile"
              placeholder="Escolha ou arraste o favicon.ico"
              drop-placeholder="Arraste o aquivo aqui..."
              accept=".ico"
              :disabled="isBusy"
              browse-text="Buscar"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
              label="Leitura de composição de caixa*"
              label-for="composicaoCaixa"
              v-if="model.hasOwnProperty('composicaoCaixa')">
              <YesOrNoOrOptionalRadio

                name="composicaoCaixa"
                :value="composicaoCaixaValue"
                :disabled="isBusy"
                @input="updatedComposicaoCaixa"
              />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Protocolo de Recebimento Obrigatório"
          >
            <YesOrNoRadio
              name="protocoloRecebimentoObrigatorio"
              :value="protocoloRecebimentoObrigatorioValue"
              :disabled="isBusy"
              @input="v => model.protocoloRecebimentoObrigatorio = v"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Máximo de materiais por protocolo de recebimento"
          >
            <b-form-input
              type="number"
              :value="model.maxRecebimentoExpurgo"
              @input="v => model.maxRecebimentoExpurgo = v"
            />

          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Quantidade de dias de protocolos pendentes"
          >
            <b-form-input
              type="number"
              :value="model.quantidadeDeDiasDeProtocolosPendentes"
              @input="v => model.quantidadeDeDiasDeProtocolosPendentes = v"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Máximo de materiais de enxoval e manufatura por esterilização"
          >
            <b-form-input
              type="number"
              :value="model.numeroMaximoDeMateriaisEnxovaisEsterilizados"
              @input="v => model.numeroMaximoDeMateriaisEnxovaisEsterilizados = v"
            />

          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Intervalo de dias para buscar lotes de equipamentos"
          >
            <b-form-input
              type="number"
              :value="model.invertavloEmDiasLotesDeEquipamento"
              @change="v => model.invertavloEmDiasLotesDeEquipamento = v"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Termo quando não tem unidade patrimônio"
          >
            <b-form-input
              type="text"
              :value="model.termoQuandoNaoTemUnidadePatrimonio"
              @input="v => model.termoQuandoNaoTemUnidadePatrimonio = v"
            />

          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Setor padrão quando não tem setor de origem"
          >
            <b-form-input
              type="text"
              :value="model.setorPadraoQuandoNaoTemSetorOrigem"
              @input="v => model.setorPadraoQuandoNaoTemSetorOrigem = v"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Horário para troca de turno diurno"
          >
            <b-form-select
              :options="optionsHoursDiurno"
              :value="model.horarioParaTrocaDeTurnoDiurno"
              @input="v => model.horarioParaTrocaDeTurnoDiurno = v"
            />

          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Horário para troca de turno noturno"
          >
            <b-form-select
              :options="optionsHoursNoturno"
              :value="model.horarioParaTrocaDeTurnoNoturno"
              @input="v => model.horarioParaTrocaDeTurnoNoturno = v"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Tempo mínimo de notificação materiais com validade próxima de vencer (EM DIAS)"
          >
            <b-form-input
              type="number"
              :value="model.diasMateriaisAVencer"
              @input="v => model.diasMateriaisAVencer = v"
            />

          </b-form-group>
       </b-col>
    </b-row>

      <b-row align-h="between" class="pt-4">
        <b-col>
          <Button
            variant="grayscale"
            @click="cancel"
            text="Cancelar"
            :disabled="isBusy"
          />
        </b-col>
        <b-col class="text-right">
          <Button
            :disabled="isBusy"
            @click="onSubmit"
            text="Salvar"
          />
        </b-col>
      </b-row>
    </form>

    <b-overlay
      no-wrap
      :show="isBusy"
      variant="light"
      opacity="0.42"
      rounded="lg"
      spinner-small
    >
      <template #overlay>
        <b-spinner
          class="spinner-save"
        />
      </template>
    </b-overlay>
  </template-base>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import genericRequest from '@/services/genericRequest';
import YesOrNoOrOptionalRadio from '@/components/Utils/YesOrNoOrOptionalRadio';
import YesOrNoRadio from '@/components/Utils/YesOrNoRadio';
import Button from '@/components/Utils/Button';
import TemplateBase from '@/templates/Base';
import loginService from '../../services/login';

export default {
  name: 'ConfigPage',
  components: {
    YesOrNoOrOptionalRadio,
    YesOrNoRadio,
    Button,
    TemplateBase,
  },
  data() {
    return {
      model: {},
      previousRoute: 'home',
      isBusy: false,
      optionsHoursDiurno: [{
        value: 1,
        text: '01:00',
      }, {
        value: 2,
        text: '02:00',
      }, {
        value: 3,
        text: '03:00',
      }, {
        value: 4,
        text: '04:00',
      }, {
        value: 5,
        text: '05:00',
      }, {
        value: 6,
        text: '06:00',
      }, {
        value: 7,
        text: '07:00',
      }, {
        value: 8,
        text: '08:00',
      }, {
        value: 9,
        text: '09:00',
      }, {
        value: 10,
        text: '10:00',
      }, {
        value: 11,
        text: '11:00',
      }, {
        value: 12,
        text: '12:00',
      }],
      optionsHoursNoturno: [{
        value: 13,
        text: '13:00',
      }, {
        value: 14,
        text: '14:00',
      }, {
        value: 15,
        text: '15:00',
      }, {
        value: 16,
        text: '16:00',
      }, {
        value: 17,
        text: '17:00',
      }, {
        value: 18,
        text: '18:00',
      }, {
        value: 19,
        text: '19:00',
      }, {
        value: 20,
        text: '20:00',
      }, {
        value: 21,
        text: '21:00',
      }, {
        value: 22,
        text: '22:00',
      }, {
        value: 23,
        text: '23:00',
      }, {
        value: 24,
        text: '24:00',
      }],
    };
  },
  computed: {
    ...mapState(['configuracao']),
    protocoloRecebimentoObrigatorioValue() {
      const map = {
        true: true,
        false: false,
      };
      return map[this.model.protocoloRecebimentoObrigatorio];
    },
    composicaoCaixaValue() {
      const fromTo = {
        opcional: null,
        sim: true,
        nao: false,
      };
      return fromTo[this.model.composicaoCaixa];
    },
  },
  mounted() {
    const conf = loginService.getConfiguracao();
    this.model = { ...conf };
  },
  methods: {
    ...mapMutations(['setConfiguracao']),
    updatedComposicaoCaixa(value) {
      if (value === null) {
        this.model.composicaoCaixa = 'opcional';
        return;
      }
      this.model.composicaoCaixa = value ? 'sim' : 'nao';
    },
    onSubmit() {
      if (this.isBusy) return;

      this.$validator.resume();

      this.$validator.validateAll().then(async (result) => {
        if (!result || this.isBusy) {
          return;
        }

        this.isBusy = true;

        this.update();
      });
    },
    cancel() {
      swal({
        title: 'Cancelamento de formulário',
        text: 'Deseja cancelar a edição das configurações?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      }).then((value) => {
        if (value) {
          this.$router.push({ name: this.previousRoute });
        }
      });
    },

    async update() {
      try {
        const formData = new FormData();

        Object.entries(this.model).forEach((entry) => {
          formData.append(entry[0], entry[1]);
        });

        await genericRequest.update(null, formData, 'configuracao');

        this.setConfiguracao({ ...this.model });

        loginService.setConfiguracao(this.model, true);

        await swal({
          title: 'Bom trabalho!',
          text: 'As configurações foram atualizadas com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });
      } catch (error) {
        console.log(error);
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            errorMessage || 'Ocorreu um problema ao tentar atualizar as configurações.',
          icon: 'error',
          button: 'Continuar',
        });
      } finally {
        this.isBusy = false;
      }
    },
    validateState(ref) {
      if (
        this.$parent.veeFields[ref]
        && document.getElementsByName(ref)[0]
        && !document.getElementsByName(ref)[0].disabled
        && (this.$parent.veeFields[ref].dirty || this.$parent.veeFields[ref].validated)
      ) {
        return !this.$parent.veeErrors.has(ref);
      }

      return null;
    },
  },
};
</script>
